<template>
  <FilterButton @click="showTeamMemberSelectModal = true">
    {{ props.placeholder ?? "Consultant" }}
    <template v-if="teamMembers.length">
      <template v-if="teamMembers.length < 4">
        <Badge v-for="teamMember in teamMembers" :key="teamMember.id" size="sm">
          {{ hasName(teamMember) ? getFullName(teamMember) : teamMember.email }}
        </Badge>
      </template>
      <Badge v-else>{{ teamMembers.length }} sélectionnés</Badge>

      <FilterClear @click.stop="teamMembers = []" />
    </template>
  </FilterButton>

  <LazyTeamMemberSelectModal
    v-if="showTeamMemberSelectModal"
    v-bind="forwarded"
    @on-close="showTeamMemberSelectModal = false"
  />
</template>

<script setup lang="ts">
import type {
  Props as TeamMemberSelectModalProps,
  Emits as TeamMemberSelectModalEmits,
} from "~/components/team-member/TeamMemberSelectModal.vue";
import { useForwardPropsEmits } from "radix-vue";
import { hasName, getFullName } from "@asap/shared";

interface TeamMemberFilterButtonProps extends TeamMemberSelectModalProps {
  placeholder?: string;
}
const props = defineProps<TeamMemberFilterButtonProps>();

const emit = defineEmits<TeamMemberSelectModalEmits>();

const { modelValue: teamMembers } = useVModels(props, emit);

const forwarded = useForwardPropsEmits(props, emit);

const showTeamMemberSelectModal = ref(false);
</script>

<style scoped></style>
